// 类型管理
// sle
<template>
  <div class="box flex-row justify-center">
    <div class="main flex justify-between">
      <!-- 左侧 -->
      <div :style="{ minHeight: minHeight + 'px' }" class="leftSide flex-column">
        <!-- 添加企业 -->
        <div v-if="disabled" class="typeSearch flex-centent" @click="changeInvite">
          <PlusOutlined style="fontsize: 20px"></PlusOutlined>
          添加企业
        </div>
        <div class="leftListTop">
          <a-menu mode="inline" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys">
            <div v-if="disabled" class="leftitem">
              <a @click="addLeftType()" class="plusicon">
                <PlusOutlined style="fontsize: 20px"></PlusOutlined>
              </a>
            </div>
            <a-menu-item key="selfent" style="width: 80%" @click="allPartners()">
              <span class="partnerName"> 全部企业 </span>
            </a-menu-item>
          </a-menu>
        </div>
        <!-- 当前类型列表 -->
        <div class="leftList">
          <a-spin :spinning="left.spinning">
            <a-menu mode="inline" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys">
              <template v-for="item of left.list" :key="item.typeId">
                <div class="leftitem">
                  <a-dropdown :disabled="moreDisabled" :trigger="['click']" v-if="item.typeId && disabled">
                    <a @click.prevent style="margin-top: 14px;font-size: 16px;">
                      <MoreOutlined />
                      <!-- &nbsp;&nbsp;&nbsp;· · · -->
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="edit" @click="editLeftItem(item.typeId)">
                          <div class="flex">
                            <img src="@/assets/bianji_icon.png" alt="" />
                            <a style="color: black; margin-left: 6px">编辑</a>
                          </div>
                        </a-menu-item>
                        <a-menu-item key="del">
                          <a-popconfirm cancelText="取消" okText="确认" :title="
                            '是否确认删除 ' +
                            item.typeName +
                            '？该分类下联系人将被移至未分类组'
                          " @confirm="submitDelLeftItem(item.typeId)">
                            <div class="flex">
                              <img src="@/assets/icon_shanchu.png" alt="" />
                              <a style="color: black; margin-left: 6px">删除</a>
                            </div>
                          </a-popconfirm>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                  <a-sub-menu>
                    <template #title>
                      <span class="externalName">{{ item.typeName }}</span>
                    </template>
                    <a-menu-item v-for="child of item.children" :key="child.id" @click="jumpBusinessDetails(child)">
                      <span class="partnerName">{{
                        child.enterpriseName
                      }}</span>
                    </a-menu-item>
                  </a-sub-menu>
                </div>
              </template>
            </a-menu>
          </a-spin>
        </div>
      </div>
      <!-- 右侧 主页 全部企业-->
      <div v-if="page === 'main'" class="right flex-column" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center justify-between">
          <div class="title">{{ left.title }}</div>
          <div class="searchInput flex-rowReverse">
            <a-form-item style="margin-top: 20px">
              <a-input-search placeholder="搜索企业" size="large" :maxLength="60" type="text" @search="partnerSearch" />
            </a-form-item>
            <div class="flex-row align-center record" @click="viewInvite" v-if="disabled"
              style="margin-right: 20px; font-size: 16px; cursor: pointer">
              <img src="@/assets/record.svg" alt="" class="black" />
              <img src="@/assets/record_blue.svg" alt="" class="blue" />
              <span>邀请记录</span>
            </div>
            <div class="flex-row align-center record" v-if="!disabled"
              style="margin-right: 20px; font-size: 16px; cursor: not-allowed">
              <img src="@/assets/record_gray.svg" alt="" style="margin-right:6px;" />
              <span style="color: gray">邀请记录</span>
            </div>
            <div class="flex-row align-center setting" @click="viewOptions"
              style="margin-right: 20px; font-size: 16px; cursor: pointer" v-if="disabled">
              <img src="@/assets/setting.svg" alt="" class="black" />
              <img src="@/assets/setting_blue.svg" alt="" class="blue" />
              <span>设置</span>
            </div>
            <div class="flex-row align-center setting" v-if="!disabled"
              style="margin-right: 20px; font-size: 16px; cursor: not-allowed">
              <img src="@/assets/setting_gray.svg" alt="" style="margin-right:6px;" />
              <span style="color: gray">设置</span>
            </div>
          </div>
        </div>
        <div class="line_h"></div>
        <a-empty v-if="maindata.list && maindata.list.length===0" description="当前暂无企业，请添加" style="margin-top:10%;" />

        <a-spin :spinning="right.spinning">
          <a-form-item class="inputCard" :wrapper-col="{ span: 24 }">
            <div class="card flex-row justify-between" :key="index" v-for="(item, index) in maindata.list"
              @click="jumpBusinessDetails(item)">
              <img v-if="item.enterpriseLogo !== null" class="logo" :src="item.enterpriseLogo" />
              <img v-if="item.enterpriseLogo === null" class="logo" src="@/assets/entlogo.svg" />
              <div class="flex-column justify-center describe">
                <div class="enterpriseName flex-row justify-between">
                  <span :title="item.enterpriseName">
                    {{ item.enterpriseName }}
                  </span>
                  <span class="forntColor">
                    添加时间：{{ item.creationTime }}
                  </span>
                </div>
                <div class="flex-row justify-between">
                  <div class="forntColor">负责人：{{ item.linkMan }}</div>
                  <div class="forntColor">
                    电话号码：{{ item.linkManPhone }}
                  </div>
                  <div class="forntColor">
                    所属类型：
                    <a-tag color="blue">
                      {{ item.typeName }}
                    </a-tag>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
        </a-spin>
        <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
          <div>
            <a-pagination hideOnSinglePage v-model:current="pagination.current" v-model:pageSize="pagination.pageSize"
              :total="pagination.total" @change="paginationChange" />
          </div>
        </a-form-item>
      </div>
      <!-- 右侧 企业详情展示  -->
      <div v-if="page === 'entInfo'" class="right flex-column" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center">
          <div class="back" @click="allPartners">
            <!-- <img src="@/assets/箭头.svg" /> -->
            <img src="@/assets/jiantou.svg" />
          </div>
          <div class="title">{{ left.title }}</div>
        </div>
        <div class="line_h"></div>
        <a-spin :spinning="right.spinning">
          <div class="body flex-column">
            <span class="rows flex-row justify-between">
              <div class="flex-row" style="width: 60%">
                <div class="name" title="企业">企业名称:</div>
                <div class="value">
                  {{ right.obj.enterpriseName }}
                </div>
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex-row">
                <div class="name">企业logo:</div>
                <img v-if="right.obj.enterpriseLogo" class="logo" :src="right.obj.enterpriseLogo" />
                <img v-if="right.obj.enterpriseLogo === null" class="logo" src="@/assets/entlogo.svg" />
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex-row">
                <div class="name">企业编号:</div>
                <div class="value">{{ right.obj.enterpriseCode }}</div>
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex-row">
                <div class="name">负责人:</div>
                <div class="value">{{ right.obj.director }}</div>
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex-row">
                <div class="name">负责人电话:</div>
                <div class="value">{{ right.obj.directorPhone }}</div>
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex" style="width: 60%">
                <div class="name">地址:</div>
                <div v-if="right.obj.detailAddress" class="value">
                  {{ right.obj.detailAddress }}
                </div>
                <div v-if="
                  right.obj.detailAddress == null ||
                  right.obj.detailAddress == ''
                " class="juniorValue">
                  对方暂时没有写地址
                </div>
              </div>
            </span>
            <span class="rows flex-row justify-between">
              <div class="flex" style="width: 80%">
                <div class="name">简介:</div>
                <div v-if="right.obj.introduction" class="value">
                  <div class="value" v-html="right.obj.introduction"></div>
                </div>
                <div v-if="right.obj.introduction == null" class="juniorValue">
                  对方暂时没有写简介
                </div>
              </div>
            </span>
            <span v-if="!selfent" class="rows flex-row justify-between">
              <div class="flex" style="width: 60%">
                <div class="name">添加时间:</div>
                <div class="value">
                  {{ right.obj.creationTime }}
                </div>
              </div>
            </span>
            <span v-if="!selfent && disabled && page === 'entInfo'" class="rows flex-row justify-between">
              <div class="flex" style="width: 60%">
                <div class="name">所属类型:</div>
                <div class="value">
                  <a-tag color="blue">
                    {{ right.obj.typeName }}
                  </a-tag>
                </div>
              </div>
              <a class="value">
                <p @click="updateClick(right.obj)">修改</p>
              </a>
            </span>
            <span v-if="!selfent && disabled && page === 'entInfo'" class="rows flex-row justify-between">
              <div class="flex" style="width: 50%">
                <div class="name">查看订单：</div>
                <div class="juniorValue">该功能即将上线，敬请期待</div>
              </div>
              <a v-if="false" class="value">
                <p @click="editClick()">查看</p>
              </a>
            </span>
            <span v-if="!selfent && disabled && page === 'entInfo'" class="rows flex-row justify-between">
              <div class="flex" style="width: 50%">
                <div class="name">关系设定：</div>
                <div class="value">
                  <a-popconfirm cancelText="取消" okText="确认" :title="'是否确认解除该企业联系人？'" @confirm="detelePartner()">
                    <div class="flex">
                      <a>解除关系</a>
                    </div>
                  </a-popconfirm>
                </div>
              </div>
            </span>
          </div>
        </a-spin>
      </div>
      <!-- 右侧 添加记录 -->
      <div v-if="page === 'record'" class="right flex-column" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center">
          <div class="back" @click="allPartners">
            <!-- <img src="@/assets/箭头.svg" /> -->
            <img src="@/assets/jiantou.svg" />
          </div>
          <div class="title">{{ left.title }}</div>
        </div>
        <div class="line_h"></div>
        <div class="body" style="margin-left: 0%">
          <a-spin :spinning="right.spinning">
            <a-list item-layout="vertical" :data-source="right.record">
              <template #renderItem="{ item }">
                <a-list-item class="item flex-column justify-between">
                  <span class="recordRows flex-row justify-between">
                    <div class="forntColor" :title="item.enterpriseName">
                      邀请企业：{{ item.enterpriseName }}
                    </div>
                    <div class="forntColor">
                      邀请时间：{{ item.creationTime }}
                    </div>
                    <div class="forntColor">
                      当前状态：
                      <a-tag v-if="item.state == null" color="cyan">
                        待处理
                      </a-tag>
                      <a-tag v-if="item.state" color="blue"> 已通过 </a-tag>
                      <a-tag v-if="item.state == false" color="red">
                        已拒绝
                      </a-tag>
                    </div>
                  </span>
                  <span class="flex-row recordbutton">
                    <a-button v-if="item.state == null && item.claimer === false" class="button" :disabled="!disabled"
                      :title="!disabled == true ? disabledtext : ''" @click="rejectClick(item)">拒绝</a-button>
                    <a-button type="primary" v-if="item.state == null && item.claimer === false" class="button"
                      :disabled="!disabled" :title="!disabled == true ? disabledtext : ''" @click="agreeClick(item)">
                      同意
                    </a-button>
                  </span>
                </a-list-item>
              </template>
            </a-list>
          </a-spin>
          <div class="flex-rowReverse">
            <a-pagination v-model:current="right.pagination.current" v-model:pageSize="right.pagination.pageSize"
              hideOnSinglePage :total="right.pagination.total" @change="paginationChange" class="pagination" />
          </div>
        </div>
      </div>
      <!-- 右侧 搜索并邀请-->
      <div v-if="page === 'invite'" class="right flex-column" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center justify-between">
          <div class="flex-row align-center">
            <div class="back" @click="allPartners">
              <!-- <img src="@/assets/箭头.svg" /> -->
              <img src="@/assets/jiantou.svg" />
            </div>
            <div class="title">{{ left.title }}</div>
          </div>

          <a-form-item class="flex-rowReverse" style="margin-top: 20px">
            <a-input-search placeholder="搜索名称，企业Id" size="large" :maxLength="60" type="text" @search="onSearch" />
          </a-form-item>
        </div>
        <div class="line_h" />
        <a-spin :spinning="right.spinning">
          <a-empty v-if="right.pagination.total === 0" :description="right.emptytxt" style="margin-top:10%;" />
          <a-form-item class="inputCard" :wrapper-col="{ span: 24 }">
            <div class="invitecard flex-row align-between" :key="index" v-for="(item, index) in right.obj.list">
              <img v-if="item.enterpriseLogo !== null" class="logo" :src="item.enterpriseLogo" />
              <img v-if="item.enterpriseLogo === null" class="logo" src="@/assets/entlogo.svg" />
              <div class="flex-column justify-center describe">
                <div :title="item.enterpriseName" class="enterpriseName">
                  {{ item.enterpriseName }}
                </div>
                <div class="flex-row justify-between">
                  <div class="forntColor">负责人：{{ item.linkMan }}</div>
                  <div class="forntColor">
                    电话号码：{{ item.linkManPhone }}
                  </div>
                  <div class="forntColor">
                    行业：
                    <a-tag color="orange">
                      {{ item.industryName }}
                    </a-tag>
                  </div>
                </div>
              </div>
              <div>
                <a-button class="jionBtn align-center" type="primary" @click="inviteClick(item)"
                  :disabled="item.adder == false ? false : true">{{ item.adder == false ? "邀请" : "已邀请" }}</a-button>
              </div>
            </div>
          </a-form-item>
        </a-spin>
        <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
          <div>
            <a-pagination hideOnSinglePage v-model:current="right.pagination.pageCount"
              v-model:pageSize="right.pagination.pageSize" :total="right.pagination.total" @change="pageChange" />
          </div>
        </a-form-item>
      </div>
      <!-- 新增/编辑类型弹窗 -->
      <company-partner-type-edit-view ref="partnertype" @editBeforeReload="editBeforeReload" />
      <!-- 联系人申请处理弹窗 -->
      <apply-dispose-view ref="disposeView" :partnerTypeList="typelist" @afterprocess="afterprocess"
        @afterinvite="afterinvite" />
      <!-- 企业设置弹窗 -->
      <a-modal v-model:visible="options.visible" :maskClosable="false" :title="options.title" :footer="false">
        <div class="options">
          <a-spin :spinning="options.spinning">
            <div class="flex justify-between" style="margin-bottom: 20px">
              查看订单进度
              <a-switch :disabled="!disabled" v-model:checked="options.viewOrderPlans" @click="editConfig()" />
            </div>
            <div class="items flex justify-between" style="margin-bottom: 20px">
              向我下单并通知
              <a-switch :disabled="!disabled" v-model:checked="options.placeOrderInforms" @click="editConfig()" />
            </div>
          </a-spin>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import dayjs from 'dayjs'
import { minheight } from '../components/sameVariable'
import { isEmpty, addressBookJurisdiction, inviteJurisdiction, tenantIsComplete } from '@/assets/common.js'
import CompanyPatnerTypeEditView from './CompanyPatnerTypeEditView'
import ApplyDisposeView from './ApplyDisposeView'
import { RedoOutlined, MoreOutlined, PlusOutlined, LeftCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    'company-partner-type-edit-view': CompanyPatnerTypeEditView,
    'apply-dispose-view': ApplyDisposeView,
    MoreOutlined,
    PlusOutlined,
  },
  data() {
    return {
      page: 'entInfo', // 当前页面
      isShow: false,
      title: {
        main: '全部企业',
        entInfo: '企业信息',
        record: '邀请记录',
        invite: '添加企业',
      },
      disabled: false,
      disabledTenant: false,
      disabledtext: '暂无权限',
      invitationDisabled: false,
      moreDisabled: false,
      minHeight: '400',
      left: {
        title: '',
        leftType: '1', // 当前选中的类型
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        spinning: false, // 是否加载
        list: [], // 左侧列表
        employeeSum: '', // 人员总数
        choise: {
          id: '', // 选中的id
          name: '', // 选中的名称
        }, // 当前选中
        ischoise: 'color:#3399ff', // 选中时颜色
        notChoise: 'color:black', // 未选中时颜色
      }, // 左侧
      right: {
        spinning: false,
        pagination: {
          current: 1,
          pageSize: 5,
          total: 0,
        }, // 分页部分
        emptytxt: '请输入搜索内容', // a-empty的展示文本
        searchInput: null,
        row: [],
        record: [], // 添加记录
        obj: {
        }, // 企业信息
      }, // 右侧
      maindata: {}, // 当前主页缓存数据
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
      }, // 主页分页部分
      options: {
        viewOrderPlans: false,
        placeOrderInforms: false,
        spinning: false,
        visible: false,
        title: '企业设置',
      },
      switch: {
        times: 0,
        limit: 0,
        visable: true,
        timer: 0,
      },
      typelist: [],
      selectedKeys: ['selfent'],
      openKeys: [],
      selfent: true, // 当前是否为本企业信息展示页
      currentinv: '', // 当前邀请的企业Id，用于邀请后标识为已邀请
    }
  },
  // 页面加载事件
  created() {
    const query = this.$route.query
    this.minHeight = minheight
    this.loadLeftList()
    this.getEnterpriseConfig()
    if (query.page) {
      if (query.page === 'record') {
        this.viewInvite()
      }
    } else {
      this.allPartners()
    }
    this.getJurisdiction()
  },
  // 方法
  methods: {
    // jurisdiction () {
    //   window.location.href = '/EnterpriseManagement/Application'
    // },
    // 刷新
    refresh() {
      this.loadLeftList()
    },
    // 倒计时
    countDown() {
      const TIME_COUNT = 5
      this.switch.limit = TIME_COUNT
      this.switch.visable = false
      this.switch.timer = setInterval(() => {
        if (this.switch.limit > 0 && this.switch.limit <= TIME_COUNT) {
          this.switch.limit--
        } else {
          this.switch.visable = true
          clearInterval(this.switch.timer)
          this.switch.timer = null
        }
      }, 1000)
    },
    allPartners() {
      this.left.title = this.title.main
      this.left.leftType = '1'
      this.prevpage = this.page
      this.page = 'main'
      this.right.searchInput = ''
      this.selectedKeys = ['selfent']
      if (!this.maindata.list) {
        this.loadRightRecord(true)
      }
    },
    partnerSearch(value) {
      this.pagination.page = 1
      this.pagination.pageCount = 1
      this.right.searchInput = value
      if (this.right.searchInput === '') {
        this.right.obj = {}
        this.pagination.total = 0
        return
      }
      this.loadRightRecord(true)
    },
    // 获取企业配置
    getEnterpriseConfig() {
      this.options.spinning = true
      api
        .get('/api/app/sys-enterprise-info/present-all-enterprise-info')
        .then(({ data }) => {
          // if (data.enterpriseLogo == null) {
          //   data.enterpriseLogo = '/img/entlogo.2224cef3.svg'
          // }
          this.options.spinning = false
          this.options.viewOrderPlans = data.viewOrderPlan
          this.options.placeOrderInforms = data.placeOrderInform
          this.selfent = true
        })
        .catch(err => {
          this.options.spinning = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // 获取权限
    getJurisdiction() {
      this.disabled = addressBookJurisdiction()
      this.disabledTenant = tenantIsComplete()
      this.invitationDisabled = inviteJurisdiction()
      if (this.disabledTenant === false) {
        this.disabled = false
        this.invitationDisabled = false
      }
      if (this.disabled === true) {
        this.moreDisabled = null
      }
    },
    // #region 左侧列表事件
    // 添加企业事件
    addLeftItem() {
      this.$refs.partnertype.addClick()
    },
    // 切换到企业邀请页
    changeInvite() {
      this.selectedKeys = []
      this.left.title = this.title.invite
      this.prevpage = this.page
      this.right.obj = {} // 清空右侧缓存
      this.page = 'invite'
      this.right.pagination = {
        current: 1,
        pageSize: 5,
        total: 0,
      }
      this.right.emptytxt = '请输入搜索内容'
    },
    // 获取类型列表数据
    loadLeftList() {
      this.left.spinning = true
      api
        .get('/api/app/company-partner/company-partner-with-type', {
        })
        .then(({ data }) => {
          this.left.spinning = false
          this.left.list = data
          this.typelist = []
          data.forEach(item => {
            if (item.typeId) {
              this.typelist.push(item)
            }
          })
          data.forEach(element => {
            if (!isEmpty(element.typeId)) {
              this.openKeys.push(element.typeId)
            }
          })
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('获取类型列表失败')
          console.log(err)
        })
    },
    // 跳转到企业信息页
    jumpBusinessDetails(data) {
      this.left.leftType = '1'
      this.page = 'entInfo'
      this.selectedKeys = [data.id]
      this.left.title = this.title.entInfo
      if (data.enterpriseId === localStorage.getItem('tenant')) {
        this.selfent = true
      } else {
        this.selfent = false
      }
      this.right.spinning = false
      // if (data.enterpriseLogo == null) {
      //   data.enterpriseLogo = '/img/entlogo.2224cef3.svg'
      // }
      data.director = data.linkMan
      data.directorPhone = data.linkManPhone
      this.right.obj = data
      if (this.right.obj.introduction) {
        this.right.obj.introduction = this.right.obj.introduction.replace(/\<img/gi, '<img style="max-width:50%;height:auto"')
      }
    },
    // 获取企业信息
    getBusinessDetails(id) {
      this.left.leftType = '1'
      this.page = 'entInfo'
      this.selectedKeys = [id]
      this.left.title = this.title.entInfo
      this.right.spinning = true
      api
        .get(`/api/app/company-partner/company-partner?id=${id}`)
        .then(({ data }) => {
          if (data.enterpriseId === localStorage.getItem('tenant')) {
            this.selfent = true
          } else {
            this.selfent = false
          }
          this.right.spinning = false
          // if (data.enterpriseLogo == null) {
          //   data.enterpriseLogo = '/img/entlogo.2224cef3.svg'
          // }
          data.director = data.linkMan
          data.directorPhone = data.linkManPhone
          this.right.obj = data
          if (this.right.obj.introduction) {
            this.right.obj.introduction = this.right.obj.introduction.replace(/\<img/gi, '<img style="max-width:50%;height:auto"')
          }
        })
        .catch(err => {
          this.right.spinning = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // #endregion

    // #region 类型管理部分
    // 添加类型按钮，呼出添加类型窗口
    addLeftType() {
      this.$refs.partnertype.addClick()
    },
    // 编辑按钮，呼出编辑类型窗口
    editLeftItem(id) {
      this.$refs.partnertype.editClick(id)
    },
    // 新增，编辑后刷新
    editBeforeReload(name, id) {
      if (this.left.choise.id === id) {
        this.left.choise.name = name
      }
      this.loadLeftList()
    },
    viewOptions() {
      this.options.visible = true
    },
    viewInvite() {
      this.selectedKeys = []
      this.left.title = this.title.record
      this.left.leftType = '2'
      this.page = 'record'
      this.loadRightRecord(false)
    },
    // 确认删除类型操作
    submitDelLeftItem(id) {
      this.left.spinning = true
      api
        .delete('/api/app/company-partner-type/company-partner-type', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          if (this.left.choise.id === id) {
            this.choiseAll()
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 解除联系人关系
    detelePartner() {
      this.right.spinning = true
      this.left.spinning = true
      // 需要当前仍处于企业信息页
      if (this.page === 'entInfo' && this.right.obj.id) {
        api
          .delete('/api/app/company-partner/company-partner', {
            params: {
              Id: this.right.obj.id,
            },
          })
          .then(({ data }) => {
            this.$message.success('操作成功')
            // 从列表中删除本次解除的企业
            this.maindata.list = this.maindata.list.filter(item => item.id != this.right.obj.id)
            this.loadLeftList()
            // 跳转回自己的企业信息页
            this.allPartners()
            
            this.right.spinning = false
            this.left.spinning = false
          })
          .catch(err => {
            // this.$message.error('操作失败')
            console.log(err)
            // 跳转回自己的企业信息页，同时刷新页面
            this.loadRightRecord(true)
            this.loadLeftList()
            this.allPartners()
          })
      }
    },
    // #endregion

    // #region 右侧 添加记录
    // 添加记录 页码变更
    paginationChange(e) {
      this.loadRightRecord(this.page === 'main')
    },
    // 获取右侧列表数据
    loadRightRecord(state) {
      this.right.spinning = true
      api
        .get('/api/app/company-partner/paging-company-partner-list', {
          params: {
            SearchInput: this.right.searchInput,
            PageSize: this.right.pagination.pageSize,
            Pagination: this.right.pagination.current,
            State: state, // 获取待处理的记录
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          if (state) {
            this.maindata = data
            this.pagination.total = data.pageCount
          }else{            
            this.right.record = data.list
            this.right.pagination.total = data.pageCount
          }
        })
        .catch(err => {
          this.right.spinning = false
          console.log(err)
        })
    },
    // 处理申请后，左右同时刷新
    afterprocess() {
      this.loadLeftList()
      this.loadRightRecord(true)
      if (this.page === 'main') {
        this.allPartners()
      } else if (this.page === 'record') {
        this.loadRightRecord(false)
      } else if (this.page === 'invite') {
        this.getEnterpriseSearch()
      } else if (this.page === 'entInfo') {
        this.getBusinessDetails(this.right.obj.id)
      } else if (this.page === 'recordDetail') {
        this.recordDetail(this.right.obj.id)
      }
    },
    afterinvite() {
      const _this = this
      var inv = this.right.obj.list.find(item => item.tenantId === _this.currentinv)
      inv.adder = true
      console.log(inv)
    },
    // 查看订单进度\下单通知开关处理
    editConfig() {
      const data = {
        placeOrderInform: this.options.placeOrderInforms,
        viewOrderPlan: this.options.viewOrderPlans,
      }
      if (this.switch.limit > 0) {
        this.$message.error('操作频繁！请稍后再试')
        return
      }
      this.switch.times++
      if (this.switch.times >= 3) {
        this.switch.times = 0
        this.countDown()
      }
      this.options.spinning = true
      api
        .post('/api/app/sys-enterprise-config/edit-config', data)
        .then(({ data }) => {
          this.options.spinning = false
          this.$message.success('操作成功')
        })
        .catch(err => {
          this.options.spinning = false
          this.$message.error('操作失败！' + err)
          console.log(err)
        })
    },
    recordDetail(id) {
      this.prevpage = this.page
      this.page = 'recordDetail'
      this.right.spinning = true
      api
        .get(`/api/app/company-partner/company-partner?id=${id}`)
        .then(({ data }) => {
          if (data.enterpriseId === localStorage.getItem('tenant')) {
            this.selfent = true
          } else {
            this.selfent = false
          }
          this.right.spinning = false
          // if (data.enterpriseLogo == null) {
          //   data.enterpriseLogo = '/img/entlogo.2224cef3.svg'
          // }
          data.director = data.linkMan
          data.directorPhone = data.linkManPhone
          this.right.obj = data
          this.left.title = data.enterpriseName
        })
        .catch(err => {
          this.right.spinning = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // #endregion

    // #region 邀请企业相关
    // 搜索
    onSearch(value) {
      this.right.pagination.page = 1
      this.right.pagination.pageCount = 1
      this.right.searchInput = value
      if (this.right.searchInput.trim() === '') {
        this.right.obj = {}
        this.right.pagination.total = 0
        return
      }
      this.getEnterpriseSearch()
    },
    // 切换分页
    pageChange(page) {
      this.right.pagination.page = page
      this.getEnterpriseSearch()
    },
    // 获取搜索数据
    getEnterpriseSearch() {
      this.right.spinning = true
      api
        .get('/api/app/company-partner/search-enterprise-info', {
          params: {
            SearchInput: this.right.searchInput,
            PageSize: this.right.pagination.pageSize,
            Pagination: this.right.pagination.page,
          },
        })
        .then(({ data }) => {
          this.right.obj = data
          this.right.row = data.list
          this.right.row.forEach(element => {
            element.creationTime = dayjs(element.creationTime).format('YYYY-MM-DD HH:mm:ss')
          })
          this.right.pagination.total = data.pageCount
          this.right.emptytxt = '未能搜索到相关结果'
          this.right.spinning = false
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('数据获取失败' + err)
          console.log(err)
        })
    },
    // #endregion

    // #region 类型选择窗口触发事件
    // 同意邀请
    agreeClick(e) {
      this.$refs.disposeView.agreeClick(e)
    },
    // 拒绝邀请
    rejectClick(e) {
      this.$refs.disposeView.rejectClick(e)
    },
    // 发起邀请
    inviteClick(e) {
      console.log(e)
      this.currentinv = e.tenantId
      this.$refs.disposeView.inviteClick(e)
    },
    // 变更联系人类型
    updateClick(e) {
      this.$refs.disposeView.updateClick(e)
    },
    // #endregion
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.box {
  width: 100%;

  .main {
    width: 82%;

    .leftSide {
      border-radius: 2px;
      width: 16%;
      background: white;
      position: fixed;
      z-index: 10;
      min-height: 410px;

      .choise {
        color: #3399ff;
      }

      .typeSearch {
        margin: 20px 10% 10px 10%;
        height: 36px;
        border-width: 1px;
        border-radius: 5px;
        border-style: dashed;
        border-color: #999999;
        cursor: pointer;
      }

      .typeSearch:hover {
        border-color: #1890ff;
        color: #1890ff;
      }

      .leftType {
        margin-top: 10px;
        width: 100%;
        height: 36px;

        .radioLeft {
          border-radius: 2px 0px 0px 2px;
        }

        .radioRight {
          border-radius: 0px 2px 2px 0px;
        }
      }

      .list {
        max-height: 86%;
        overflow: auto;
        overflow-x: hidden;
      }

      .all {
        margin-top: 10px;
        padding-left: 10%;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0px;

        a: {
          color: black;
        }
      }

      .title {
        width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .back {
        cursor: pointer;
      }

      .leftListTop {
        width: 100%;
        margin-top: 5px;

        .leftitem {
          margin-right: 10%;
          width: 84%;
          height: auto;

          .plusicon {
            margin-top: 14px;
            font-size: 18px;
            position: absolute;
            left: 86%;
            color: grey;
          }

          .plusicon::before {
            content: "添加类型";
            position: absolute;
            width: 100px;
            background-color: white;
            color: black;
            text-align: center;
            padding: 10px;
            line-height: 1.2;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
            bottom: -40px;
            font-size: 0.75em;
            visibility: hidden;
            word-break: break-all;
            box-shadow: 2px 2px 4px 2px rgba(128, 128, 128, 0.5);
          }

          .plusicon:hover:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .leftList {
        width: 100%;
        margin-top: 5px;
        min-height: 120px;
        overflow: auto;
        max-height: 400px;

        .leftitem {
          margin-right: 10%;
          width: 84%;
          height: auto;
        }

        .leftItemTitle {
          width: 76%;
        }

        .ant-list-item {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .externalName {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .partnerName {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .ant-dropdown-trigger {
          position: absolute;
          left: 87%;
          // margin-top: -8px;
        }
      }
    }

    .options {
      margin-top: auto;
      margin-left: 10%;
      margin-right: 10%;

      .items {
        margin-bottom: 14px;
      }
    }

    .right {
      margin-left: 21%;
      width: 81%;
      background-color: #fff;
      border-radius: 5px;

      .head {
        letter-spacing: 1px;
        background: #fff;
        padding: 0px 20px;
        border-radius: 5px;
        height: 60px;
        font-size: 18px;

        .searchInput {
          .setting {
            .blue {
              margin-right: 6px;
              display: none;
            }

            .black {
              margin-right: 6px;
              display: block;
            }
          }

          .setting:hover {
            color: #1890ff;

            .blue {
              margin-right: 6px;
              display: block;
            }

            .black {
              margin-right: 6px;
              display: none;
            }
          }

          .record {
            .blue {
              margin-right: 6px;
              display: none;
            }

            .black {
              margin-right: 6px;
              display: block;
            }
          }

          .record:hover {
            color: #1890ff;

            .blue {
              margin-right: 6px;
              display: block;
            }

            .black {
              margin-right: 6px;
              display: none;
            }
          }
        }

        .back {
          margin-right: 10px;
          cursor: pointer;
          font-size: 18px;
        }

        .right-button {
          position: relative;
          // top: 20px;
        }

        .button {
          font-size: 16px;
          cursor: pointer;
          color: #3479db;
        }

        .img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }

        .inputSearch {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .body {
        margin-left: 3%;

        .rows {
          width: 90%;
          margin: 10px;
          min-height: 40px;
          line-height: 40px;

          .value {
            min-width: 100px;
          }

          .juniorValue {
            color: gray;
            min-width: 100px;
          }

          .name {
            width: 120px;
            float: right;
          }
          .logo {
            object-fit:scale-down;
            height: 70px;
            // background-color: #134CCF;
          }
        }

        .recordRows {
          width: 90%;
          margin: 10px;

          //min-height: 25px;
          //line-height: 25px;
          .forntColor {
            font-size: 14px;
            width: 40%;
            margin-left: 20px;
          }
        }

        .recordbutton {
          position: absolute;
          left: 80%;
          margin-top: 5px;

          .button {
            margin-left: 20px;
          }
        }
      }

      .bottom {
        margin-top: -20px;
        text-align: right;
        font-size: 12px;
      }

      .card {
        width: 100%;
        height: 100px;
        border-bottom: 1px solid #f5f5f5;
        position: relative;
        cursor: pointer;

        .describe {
          width: 100%;
        }

        .logo {
          width: 70px;
          height: 70px;
          margin: 15px 15px;
          // background-color: #134CCF;
        }

        .enterpriseName {
          width: 100%;
          height: 24px;
          line-height: 24px;
          margin: 12px 0px;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: rgb(55, 88, 179);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .forntColor {
            font-size: 12px;
            color: #999;
            width: 40%;
          }
        }

        .forntColor {
          font-size: 14px;
          width: 40%;
        }

        .state {
          display: inline-block;
          text-align: center;
          border-radius: 3px;
        }

        .objState {
          width: 54px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          color: #3200e68f;
          background-color: #e1edff;
          border: 1px solid #2986f1;
        }

        .jionBtn {
          position: absolute;
          top: 44px;
          right: 4%;
          height: 34px;
          width: 80px;
          //line-height: 34px;
          text-align: center;
          // border: 1px solid #ccc;
          // background-color: #ccc;
          // color: #fff;
        }
      }

      .card:hover {
        background-color: #edf4ff;
      }

      .invitecard {
        width: 100%;
        min-height: 48px;
        border-bottom: 1px solid #f5f5f5;
        position: relative;

        .describe {
          width: 100%;
        }

        .logo {
          width: 60px;
          height: 60px;
          margin: 15px 15px;
          // background-color: #134CCF;
        }

        .enterpriseName {
          width: 100%;
          height: 24px;
          line-height: 24px;
          margin: 12px 0px;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: rgb(55, 88, 179);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .forntColor {
            font-size: 14px;
            color: #999;
            width: 40%;
          }
        }

        .forntColor {
          font-size: 14px;
          width: 40%;
        }

        .state {
          display: inline-block;
          text-align: center;
          border-radius: 3px;
        }

        .objState {
          width: 54px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          color: #3200e68f;
          background-color: #e1edff;
          border: 1px solid #2986f1;
        }

        .jionBtn {
          position: absolute;
          top: 28px;
          right: 4%;
          height: 34px;
          width: 80px;
          //line-height: 34px;
          text-align: center;
          // border: 1px solid #ccc;
          // background-color: #ccc;
          // color: #fff;
        }
      }

      .buttonGroup {
        height: 80px;

        .button {
          margin-right: 20px;
        }

        .departmentButtons {
          height: 100%;
        }

        .search {
          width: 200px;
          margin-left: 24px;
        }

        .invite {
          color: black;
          margin-left: 40px;
        }
      }

      .table {
        padding: 0px 24px;
        margin: 0;
      }

      .editHide {
        font-size: 16px;
        color: #b7b7b7;
        cursor: not-allowed;
      }

      .hide {
        color: #b7b7b7;
        cursor: not-allowed;
      }
    }
  }
}

.list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

.list::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}

.list::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

::v-deep(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background: white;
}

::v-deep(.ant-menu-inline .ant-menu-item::after) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-right: none;
}

::v-deep(.ant-table-bordered .ant-table-thead)>tr>th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}

::v-deep(.ant-table-bordered .ant-table-tbody)>tr>td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}

::v-deep(.ant-table-bordered .ant-table-fixed-right) table {
  border-right: 1px solid #f0f0f0;
}

::v-deep(.ant-input-affix-wrapper-lg) {
  flex-direction: row-reverse;
}

::v-deep(.ant-input-suffix) {
  margin-left: 0px;
  padding-right: 6px;
}
</style>
