// 企业联系人申请处理弹窗
<template>
  <div id="ApplyDisposeView">
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="确定"
      cancel-text="取消"
      @ok="editSubit"
      @cancel="closeView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          @finish="editSubit"
        >
          <a-form-item
            v-if="result"
            label="选择类型"
            class="select"
            :required="true"
          >
            <a-select v-model:value="typeId" placeholder="请选择类型">
              <a-select-option
                v-for="r in partnerTypeList"
                :key="r.typeId"
                :value="r.typeId"
                >{{ r.typeName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <div v-if="!result">确定拒绝该企业的联系人申请吗？</div>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { isEmpty } from '@/assets/common.js'

export default defineComponent({
  emits: ['afterprocess', 'afterinvite'],
  props: {
    partnerTypeList: {
      type: Array,
    },
  },
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      result: false, // 处理方式
      title: '', // 弹窗标题
      typeId: null,
      scene: 'process', // 进入场景
      obj: {
      },
    }
  },
  methods: {
    // 拒绝按钮
    rejectClick (e) {
      this.visible = true
      this.title = '拒绝申请'
      this.result = false
      this.scene = 'process'
      this.obj = e
    },
    // 同意按钮
    agreeClick (e) {
      this.visible = true
      this.title = '选择类型'
      this.result = true
      this.scene = 'process'
      this.obj = e
    },
    // 邀请企业
    inviteClick (e) {
      this.visible = true
      this.title = '选择类型'
      this.result = true
      this.scene = 'invite'
      this.obj = e
    },
    // 变更联系人类型
    updateClick (e) {
      this.visible = true
      this.title = '选择类型'
      this.result = true
      this.scene = 'update'
      this.obj = e
    },
    // 关闭窗口
    closeView () {
      this.visible = false
      this.title = ''
      this.result = false
      this.obj = {}
      this.typeId = null
    },
    // 提交按钮
    editSubit () {
      if (this.scene === 'update') {
        const obj = {
          id: this.obj.id,
          state: this.obj.state,
          typeId: this.typeId,
          tenantId: this.obj.tenantId,
          partnerId: this.obj.partnerId,
          claimer: this.obj.claimer,
          concurrencyStamp: this.obj.concurrencyStamp,
        }
        const requesturl = '/api/app/company-partner/company-partner'
        if (isEmpty(this.typeId)) {
          this.$message.error('请选择类型')
          return
        }
        this.spinning = true
        api
          .put(requesturl, obj)
          .then(({ data }) => {
            this.$message.success('操作成功')
            this.closeView()
            this.$emit('afterprocess')
            this.spinning = false
          })
          .catch(err => {
            this.spinning = false
            this.$message.error('请求失败，请刷新页面后重试')
            console.log(err)
          })
      } else {
        var obj = {
          id: this.obj.id,
          result: this.result,
          typeId: '',
        }
        var requesturl = '/api/app/company-partner/dispose-company-partner-request'
        if (this.scene === 'invite') {
          obj = {
            partnerId: this.obj.tenantId,
            typeId: '',
          }
          requesturl = '/api/app/company-partner/company-partner'
        }
        if (this.result && isEmpty(this.typeId)) {
          this.$message.error('请选择类型')
          return
        } else {
          obj.typeId = this.typeId
        }
        this.spinning = true
        api
          .post(requesturl, obj)
          .then(({ data }) => {
            this.$message.success('操作成功')
            this.closeView()
            console.log(this.scene)
            if (this.scene === 'invite') {
              this.$emit('afterinvite')
            } else {
              this.$emit('afterprocess')
            }
            this.spinning = false
          })
          .catch(err => {
            this.spinning = false
            console.log(err)
          })
      }
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.select {
  margin-top: 15px;
}
</style>
