// 类型新增，编辑弹窗
// sle
<template>
  <div>
    <a-modal
      :confirmLoading="spinning"
      width="500px"
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      @ok="viewSubitClick"
      @cancel="closeView"
    >
      <a-spin :spinning="spinning">
        <a-form
          ref="formRef"
          :model="obj"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          @finish="viewSubitClick"
        >
          <a-form-item label="类型名称" required name="partnerName">
            <a-input
              v-model:value="obj.partnerName"
              required
              :maxlength="30"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['editBeforeReload'],
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      add: false, // 是否新增操作
      title: '', // 弹窗标题
      obj: {
        id: '',
        partnerName: '', // 类型名称
      },
      rules: {
        partnerName: [
          {
            required: true,
            message: '请输入类型名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '长度请控制在30以内',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    addClick () {
      this.visible = true
      this.title = '添加类型'
      this.add = true
    },
    // 编辑按钮，呼出编辑类型窗口
    editClick (id) {
      this.getSelectData(id)
    },
    // 获取选中类型的详情数据
    getSelectData (id) {
      this.spinning = true
      api
        .get('/api/app/company-partner-type/company-partner-type', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          this.visible = true
          this.title = '编辑'
          this.add = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取类型信息失败')
          console.log(err)
        })
    },
    // 取消，关闭类型编辑窗口
    closeView () {
      this.visible = false
      this.title = ''
      this.add = false
      this.$refs.formRef.resetFields()
      this.obj = {}
    },
    // 类型编辑弹窗确认按钮
    viewSubitClick () {
      this.add ? this.addClickSubit() : this.editClickSubit()
    },
    // 确认添加操作
    addClickSubit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const name = this.obj.partnerName
          api
            .post('/api/app/company-partner-type/or-update-company-partner-type', {
              typeName: this.obj.partnerName,
            })
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.closeView()
              this.$emit('editBeforeReload', name)
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    // 确认编辑操作
    editClickSubit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const name = this.obj.partnerName
          const id = this.obj.id
          api
            .post('/api/app/company-partner-type/or-update-company-partner-type', {
              typeName: this.obj.partnerName,
              id: id,
              concurrencyStamp: this.obj.concurrencyStamp,
            })
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.closeView()
              this.$emit('editBeforeReload', name, id)
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>
<style>
::v-deep(.ant-col .ant-col-4 .ant-form-item-label) {
  overflow: inherit;
  display: block;
  flex: 0 0 15%;
  max-width: none;
}
::v-deep(.ant-col .ant-col-14 .ant-form-item-control-wrapper) {
  display: block;
  flex: 0 0 75%;
  max-width: none;
}
</style>
